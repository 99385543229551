<template>
  <div class="footer">
    <div class="item1">
        <p>Federation of IPUMON 2022-23 &copy;</p>
    </div>
    <div class="item2">
        <p>IPUMON service</p>
        <ul>
          <li><router-link to="/about">Misson</router-link></li>
          <li><a href="https://docs.google.com/spreadsheets/d/1NhHAJ7g9Pfi4__LtdHaDYynhl6p0l_6DPAtKYjz1yNI/edit#gid=1766308720">Member Dues-Credit</a></li>
          <li><a target="_blank" href="https://docs.google.com/document/d/1jfES4keBd-OeKabrr0X58oZfNB9fXBqYKwQS-qTK-FU/edit?usp=sharing">Funds Report 2023</a></li>
        </ul>
    </div>
    <div class="item3">
        <p>Member</p>
        <ul>
        <li><a href="mailto:help@ipumon.com?subject=Issue with ipumon.org&body=What page did the error or breaked occurred:">Report Issues</a></li>
        <template v-if="!$store.state.user"><li ><router-link to="/auth/login">Login</router-link></li></template>
        <template v-if="$store.state.user"><li><a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSf16kdgeKKQmJOEFRLcGnaXml4cbl2xi52sGfR2Lut_nsnQsg/viewform">Suggest</a></li></template>
        </ul>
    </div>
    <div class="item4">
        <p>IPUMON Terms</p>
        <ul>
        <li><a target="_blank" href="https://web-c.ipumon.net/terms">Terms-Of-Use</a></li>
        <li><a target="_blank" href="https://web-c.ipumon.net/Web-Privacy-Policy">Web-Privacy-Policy</a></li>
        <!-- <li><a target="_blank" href="/Data-Cookie-Policy">Data-Cookie-Policy</a></li> -->
        </ul>
    </div>      
  </div>  
</template>

<style>
.item1 {
  grid-area: note;
}
.item2 {
  grid-area: link1;
}
.item3 {
  grid-area: link2;
}
.item3 {
  grid-area: link3;
}
.item1 p, .item1-a p{
  float: right;
  color: black;
  font-size: 15px;
}
div .footer {
  display: grid;
  background-color: #999;
  grid-template-areas: 
  'link1 link2 link3 note'
  'link5 link6 link7 note';
  padding: 5%;
  width: 100%;
  
}
ul {
  list-style-type: none;
  margin-right: 0;
  overflow: hidden;
  font-size: 20px;
}
li a {
  text-decoration: none;
  color: black;
  font-size: medium
}
</style>
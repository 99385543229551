import { createStore } from 'vuex'
import db from '../firebase/firebaseInit.js'
import router  from '../router'
import  { auth }  from '../firebase/firebaseInit'
import {collection, getDocs, getDoc, query, where, doc, updateDoc, setDoc } from "firebase/firestore";
import { signInWithEmailAndPassword, signOut,isSignInWithEmailLink,sendPasswordResetEmail, onAuthStateChanged, signInWithEmailLink, sendSignInLinkToEmail } from 'firebase/auth'

const store = createStore({
  state: {
    // user
    // Useremail: "test@ipumon.net",
    user: null,
    authIsReady: false,
    profileLoaded: null,
    //    
    OnlineFee: .79,
    onlineRate: .0379,
    RequestAmount: 0.00,
    Payed: false,
    //
    LoadData: [],
    LoadedDate: null,
    LoadSearch: null,
    //
    Request: [],
    request_Loaded: null,
    //
    PostsData: [],
    LoadedDate: null,
  },
  getters: {
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setAuthIsReady(state, payload) {
      state.authIsReady = payload
    }, 
    SetLoadData(state, payload) {
      state.LoadData.push(payload);
    },
    loaddate_loaded(state) {
      state.LoadedDate = true
    },
    // Post
    SetPosts(state, payload) {
      state.PostsData.push(payload);
    },
    loadPosts_loaded(state) {
      state.LoadedPost = true
    },
    post_title(state, payload) {
      state.post_title = payload
    },
    post_text(state, payload) {
      state.post_text = payload
    },
    post_link(state, payload) {
      state.post_link = payload
    },
    post_call(state, payload) {
      state.post_call = payload
    },
    post_num(state, payload) {
      state.post_num = payload
    },
    post_location(state, payload) {
      state.post_location = payload
    },
    // end of post
    SetLoadSearch(state, payload) {
      state.LoadSearch.push(payload);
    },
    SetRequest(state, payload) {
      state.Request.push(payload);
    },
    requestdate_loaded(state) {
      state.request_Loaded = true;
    },
    profileLoaded(state) {
      state.profileLoaded = true;
    },
    setProfileInfo(state, doc) {
      state.profileId = doc.id;
      state.profileFirstName = doc.data().FirstName;
      state.profileLastName = doc.data().LastName;
      state.profileUsername = doc.data().Username;
      state.profileEmail = doc.data().user_Email;
      state.profileNumber = doc.data().user_Phone_Number;
      state.profileBirthday = doc.data().user_BirthDay;
      state.profileZip = doc.data().user_Zip;
      state.collectives = doc.data().Collectives;
      state.credits = doc.data().Credits;
      state.role = doc.data().Role;
    },
    changeZip(state, payload) {
      state.profileZip = payload;
    },
    changeNumber(state, payload) {
      state.profileNumber = payload;
    },
    changeEmail(state, payload) {
      state.profileEmail = payload;
    },
  },
  actions: {
    async login(context, { email, password }) {
      const res = await signInWithEmailAndPassword(auth, email, password)
      if (res) {
        context.commit('setUser', res.user)
      } else {
        throw new Error('could not complete login')
      }
    },
    async forgot(context, { email }) {
      const res = await sendPasswordResetEmail(auth, email)
      throw new Error('Check your email, copy that password to this site.')
    },
    async loginlink(context, {email}) {
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: 'http://localhost:8080/auth',
        // This must be true.
        handleCodeInApp: true,
        // dynamicLinkDomain: 'www.ipumon.org'
      };
      // console.log('login', context)
      const res2 = await sendSignInLinkToEmail(auth, email, actionCodeSettings)
      window.localStorage.setItem('emailForSignIn', email);
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt('Please provide your email for confirmation');
        }
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');
            // You can access the new user via result.user
            // Additional user info profile not available via:
            // result.additionalUserInfo.profile == null
            // You can check if the user is new or existing:
            // result.additionalUserInfo.isNewUser
          })
      }
    },
    async logout(context) {
      await signOut(auth)
      context.commit('setUser', null)
      router.push("/")
    },
    // user service
    fetchUser ({ commit }) {
      var currentUrl = window.location.pathname;
      auth.onAuthStateChanged(async user => {
        if (user === null){
          return
        } else {
          commit('setUser', user)
          const useruid = user.uid
          const docRef = doc(db, "Users", useruid);
          const docSnap = await getDoc(docRef);
          commit("setProfileInfo", docSnap);
          // commit("setProfileInitials");
          commit("profileLoaded")
          if(router.isReady() && router.currentRoute.value.path === '/auth') {
            router.push(currentUrl)
          }
        }
      })
    },
    async updateUserSettings({ state, commit }) {
      auth.onAuthStateChanged(async user => { 
        commit('setUser', user)
        const useruid = user.uid
        const dataBase = await doc(db, "Users", useruid);
        await updateDoc(dataBase,{
          user_Zip: state.profileZip,
          user_Phone_Number: state.profileNumber,
          user_Email: state.profileEmail,
          user_Zip: state.profileZip,
        });
      })
    },
    // data request
    async DataSearch({commit, state}) {
    const getData = query(collection(db, 'Data'), where("LoadCode", "==", state.LoadSearch));
      const results = await getDocs(getData)
      results.forEach(doc => {
        if (!state.LoadData.some((data) => data.docId === doc.id)) {
          const data = {
            docId: doc.id,
            LoadCode: doc.data().LoadCode,
            Type: doc.data().Type,
            Action: doc.data().Action,
            dataDate: doc.data().Data,
            Time: doc.data().Time,
            // dataDate: new Date(doc.data().Date).toLocaleDateString("en-uk"),

          };
          commit("SetLoadData", data);
        }
      });
      commit('loaddate_loaded')
    },
    async OFFset_Data() {
      this.state.sitetime = new Date(Date.now()).toLocaleDateString("en-uk")
      this.state.time = new Date(Date.now()).toLocaleTimeString()
      this.state.site = window.location.hostname
      this.state.action = window.location.pathname
      console.log("              https://IPUMON.ORG/DATA      load timestamp: "+this.state.sitetime+ " & Time at "+this.state.time+" &               Load ID: "+this.state.RequestID+" &           Site: "+this.state.site+" &           Action: "+this.state.action )
      const dataBase = doc(db, "Data", this.state.RequestID+"-"+this.state.time);
          setDoc(dataBase,{
          LoadCode: this.state.RequestID,
          Data: this.state.sitetime,
          Time: this.state.time,
          Action: this.state.action,
          Site: this.state.site,
          });
    },
    async set_Payed() { 
      const dataBase = doc(db, "Request", this.state.profileUsername);
      updateDoc(dataBase,{
          Payed: true,
          PayLog: this.state.RequestID,
          Data: this.state.sitetime
      });
    },
    async PostsInfo({commit, state}) {
      const getData = query(collection(db, 'Posts'));
        const results = await getDocs(getData)
        // const result = await getDocs(getData)
        results.forEach(doc => {
          if (!state.PostsData.some((data) => data.docId === doc.id)) {
            const data = {
              docId: doc.id,
              Title: doc.data().title,
              Text: doc.data().text,
              Link: doc.data().link,
              Call: doc.data().call,
              Location: doc.data().location,
              
            };
            commit("SetPosts", data);
          }
        });
        commit('requestdate_loaded')
    },
    async RequestSearch({commit, state}) {
      const getData = query(collection(db, 'Request'), where("Account", "==", this.state.profileUsername));
        const results = await getDocs(getData)
        // const result = await getDocs(getData)
        results.forEach(doc => {
          console.log('ok request', results)
          if (!state.LoadData.some((data) => data.docId === doc.id)) {
            const data = {
              docId: doc.id,
              Account: doc.data().Account,
              Items: doc.data().Items,
              Payed: doc.data().Payed
            };
            commit("SetRequest", data);
          }
        });

        commit('requestdate_loaded')
    },
  
  } // end of actions
})

const unsub = onAuthStateChanged(auth, (user) => {
  store.commit('setAuthIsReady', true)
  store.commit('setUser', user)
  unsub()
})

// export the store
export default store
<template>
  <main>
    <div class="module" id="post">
        <h3>Welcome to IPUMON member services,</h3>
        <p>here you can view infomation about IPUMON. What is IPUMON all about </p>
        <p>IPUMON's purpose is to benefit its network members and project partners 
          by offering a platform and service that allowing members thrive in an 
          ever-changing world. IPUMON hosted service that allows you buying and selling 
          you service and goods.
        </p>
        <p></p>
        <router-link to="/about"><button>About</button></router-link>
    </div>
    <div class="module">
        <h3>Member Login or Join</h3>
        <router-link to="/join"><button>Become a Member</button></router-link>
        <a href="/my/"><button>Are a Member</button></a>
    </div>
    <div v-for="post in PostsData" :key="post.id">
      <div class="module" v-if="post.Location === 'home'">
        <h3>{{post.Title}}</h3>
        <p>{{post.Text}}</p>
        <router-link :to="post.Link">
          <button>{{post.Call}}</button>
        </router-link>
      </div>
    </div>
  </main>
</template>
<script>
import {mapState, mapActions} from "vuex";
export default {
  created() {
    if (!this.$store.state.loadPosts_loaded) {
      this.PostsInfo();
    }
  },
  methods: {
    ...mapActions(['PostsInfo', 'loadPosts_loaded']),
  },
  computed: {
    ...mapState(["PostsData"]),
  }
}
</script>
<style>
.module {
  border: 1px solid black;
  margin: 0;
  padding: 5%;
  margin-top: 5%;
}
</style>
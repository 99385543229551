<template>
  <header v-if="!mobile" class="noPrint">
    <NavBar/>
  </header>
  <Modal v-if="mobile"/>
  <router-view v-if="!mobile"/>
  <footer v-if="!mobile" class="noPrint" style="margin-top: 300px;">
    <FooTer/>
  </footer>
  <!-- <DataWarn/> -->
</template>
<script>
// @ is an alias to /src
import { onBeforeMount } from 'vue'
import { useStore, mapActions } from 'vuex'
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
import { uid } from 'uid'
import Modal from './components/Other/Modal.vue';
import DataWarn from './components/Other/DataWarn.vue'
export default {
  name: 'HomeView',
  components: {NavBar, FooTer, Modal, DataWarn},
  data() {
    return {
      mobile: null,
    }
  },
  setup() {
    const store = useStore()
    onBeforeMount(() => {
      store.dispatch('fetchUser')
    })
  },
  created() {
    this.$store.state.Year = new Date(Date.now()).toLocaleDateString("en-uk", {year: "numeric"} )
    this.$store.state.RequestID = this.$store.state.Year+"-"+uid(8)
    this.$store.state.sitetime = new Date(Date.now()).toLocaleDateString("en-uk" )
    this.checkScreen();
    window.addEventListener("resize", this.checkScreen);
  },
  methods: {
    ...mapActions(["fetchcontent"]),
    checkScreen() {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
    },
  }
}
</script>
<style>
* {
  box-sizing: border-box;
}
main {
  padding: 5%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.print {
    display: none;
}
@media print {
   .noPrint{
    display:none;
  }
   .print {
    display: inline;
  }
}
input[type=text], input[type=number], textarea, select {
  width: 100%;
  padding: 2% 2%;
  margin: .5% 0;
  box-sizing: border-box;
}
button {
  background-color: #cc0099;
  border: none;
  color: white;
  padding: 15px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
#items {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#items-z {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  float: right;
  width: 35%;
}

#items td, #items th, #items-z td, #items-z th {
  border: 1px solid #ddd;
  padding: 8px;
}

#items tr:nth-child(even), #items-z tr:nth-child(even) {background-color: #f2f2f2;}

#items tr:hover,#items-z tr:hover {background-color: #ddd;}

#items th, #items-z th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #cc0099;
  color: white;
}

.box {padding: 10% 30% 0% 30%;}
.form {border: 3px solid #f1f1f1;}

.box input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.box button {
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

button:hover {
  opacity: 0.8;
}

.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

.container {
  padding: 16px;
}

span.psw {
  float: right;
  padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 500px) {
  span.psw {
     display: block;
     float: none;
  }
  .cancelbtn {
     width: 100%;
  }
  .box {
     padding: 0% 0% 0% 0%;
  }
}
</style>

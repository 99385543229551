<template>
    <div class="DataWarn noPrint">
        <p>IPUMON Data Usage Notice.</p>
        <router-link to="/data">See Here.</router-link>
    </div>
</template>
<style>
div.DataWarn {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #999;
  padding: 10px;
  font-size: 20px;
}

</style>
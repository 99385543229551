<template>
  <div class="modal flex">
    <div class="modal-content">
        <h1>Collective-Services<a target="_blank" href="https://www.ipumon.com/#UA"><b id="backUA">IPUMON </b></a></h1>
        <p>Sorry this site is not available on mobile devices. Only available on a tablet or computer.</p>
        <button><a href="mailto:?subject=Check out this site.&body=HTTPS://IPUMON.ORG/ABOUT">Send an email.</a></button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "modal",
  methods: {
    ...mapMutations(["TOGGLE_MODAL", "TOGGLE_INVOICE", "TOGGLE_EDIT_INVOICE"]),
    closeModal() {
      this.TOGGLE_MODAL();
    },
    closeInvoice() {
      this.TOGGLE_MODAL();
      this.TOGGLE_INVOICE();
      if (this.editInvoice) {
        this.TOGGLE_EDIT_INVOICE();
      }
    },
  },
  computed: {
    ...mapState(["editInvoice"]),
  },
};
</script>

<style>
a {
  display: inline-block;
  color: black;
  text-align: center;
  text-decoration: none;
}
</style>
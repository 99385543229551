import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC4gx10VMHek4xOVOvxGEqy_NM4jCZmWfw",
  authDomain: "collective-service-ipumon.firebaseapp.com",
  projectId: "collective-service-ipumon",
  storageBucket: "collective-service-ipumon.appspot.com",
  messagingSenderId: "108115481849",
  appId: "1:108115481849:web:050cd78fbab62d268bbe28"
};
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { auth,storage }
export default db
import { createRouter, createWebHistory } from 'vue-router'
// import Login from '../views/Auth/LogIn.vue'
import { auth } from '../firebase/firebaseInit'
// import NotFound from '../views/NotFound.vue'
import HomeView from '../views/HomeView.vue'
import store from "@/store"

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    redirect: '/'
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Public/AboutView.vue')
  },
  {
    path: '/application',
    name: 'application',
    component: () => import( '../views/Public/AppLication.vue')
  },
  {
    path: '/join',
    name: 'join',
    component: () => import( '../views/Public/Join.vue')
  },
  {
    path: '/dues',
    name: 'Public Member Dues',
    component: () => import('../views/Public/AboutDues.vue')
  },
  {
    path: '/data',
    name: 'public data',
    component: () => import('../views/Public/Data.vue')
  },
  // public help
  {
    path: '/help/pdf',
    name: 'Public PDF help',
    component: () => import('../views/Help/PDF.vue')
  },
  // end of public help
  // terms
  // end of terms
  {
    path: '/terms/payment',
    name: 'Terms for Payment',
    component: () => import('../views/Public/Terms/Payment.vue')
  },
  {
    path: '/terms/temporary-agreement',
    name: 'temporary-agreement',
    component: () => import('../views/Public/Terms/Temp.vue')
  },
  {
    path: '/terms/',
    name: 'terms',
    component: () => import('../views/Public/Terms/TermS.vue')
  },
  {
    path: '/terms/web-privacy-policy',
    name: 'policy',
    component: () => import('../views/Public/Terms/PrivacyPolicy.vue')
  },
  //  frame
  {
    path: '/frame/star',
    name: 'frame star',
    component: () => import('../views/Public/frame/star.vue')
  },
  {
    path: '/frame',
    name: 'frame viewer',
    component: () => import('../views/Public/frame/viewer.vue')
  },
  // end of frame
  // end of public
  // auth
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('../views/Auth/LogIn.vue')
  },
  // {
  //   path: '/auth',
  //   name: 'PassWord-Less',
  //   component: () => import('../views/Auth/PassWordLess.vue')
  // },
  {
    path: '/auth/forgot',
    name: 'forgot',
    component: () => import('../views/Auth/ForgotPass.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/auth/help',
    name: 'reset',
    component: () => import('../views/Auth/Help.vue')
  },
  // end of auth
  // payments
  {
    path: '/payment/dues/',
    name: 'Paypal Member Dues',
    component: () => import('../views/Payments/PayDues.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // end of payments
  // member services
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Member_Serivces/DashBoard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Member_Serivces/ProFile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my/hub',
    name: 'Member Hub',
    component: () => import('../views/Member_Serivces/MemberHub.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/c/:coll',
    name: 'pass',
    component: () => import('../views/Member_Serivces/MyCollectives.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my/',
    name: 'My Collectives',
    component: () => import('../views/Member_Serivces/My.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my/credits',
    name: 'MY credits',
    component: () => import('../views/Member_Serivces/MemberCredits.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // end of member services 
  // Admin
  {
    path: '/admin/post',
    name: 'Posts',
    component: () => import('../views/Admin/Posts.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/member',
    name: 'Memeber List',
    component: () => import('../views/Admin/MemberList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/account',
    name: 'Account Manager',
    component: () => import('../views/Admin/AccountManager.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // end of Admin
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes, 
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || {top:0}
  }
})
// router.afterEach((to, from) => {
//   store.dispatch("set_Data")
// })
router.beforeEach((to, form, next) => {
  // const browserHeaderTitle = to.name
  const currentUrl = window.location.pathname;
  if (to.path === currentUrl && auth.currentUser) {
    next()
    return;
  }
  
  if (to.matched.some(record => record.meta.requiresAuth) && !auth.currentUser) {
    next("/auth/login")
    return;
  }

  next();
})

export default router
